export const AUTH_API_URL = {
  LOGIN: "/auth/getClientToken",
  CHECK_LOGIN: "/auth/checkExistingLoginSession",
  GET_SESSION: "/api/APIAppManager/LoginSession/AddLoginSession",
  GET_USER: "/api/APIAppManager/User/GetUsers",
  USER_FACILITY: "/api/APIAppManager/User/GetFacilities",
  GET_CONTROL: "/api/APIAppManager/Control/GetControls",
  GET_PATIENT_ALERT: "/api/APIgeneralmaster/PatientAlert/GetPatientAlerts",
  LOGOUT: "/auth/ClientLogout",
  USER_ROLE: "/api/APIAppManager/Role/GetRoles",
  REFRESH_TOKEN: "/auth/refresh",
};
